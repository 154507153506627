import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
// import LayoutInner from "common/components/Layout";
// import Titlebar from "common/components/Titlebar";
// import FAQToggle from "common/components/FAQs";
import "common/assets/css/main-page.css";
import Seo from "components/seo";
import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const NonSurgicalHairReplacementforMen = () => {

  
  const seoData = {
    title: 'Non Surgical Hair Replacement for Men In Delhi - Radiance',
    description: 'Explore the best non surgical hair replacement for men in Delhi. Radiance Hair Studio offers natural-looking solutions to hair loss. Book your consultation now.',
    keywords: ['non surgical hair replacement for men in Delhi'],
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "areaServed": "New Delhi",
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "image": [
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/service-images/Non-Surgical-Hair-Replacement-for-Men's.jpg",
      "https://www.radiancehairstudio.com/non-surgical-hair-replacement-for-men1.png",
      "https://www.radiancehairstudio.com/non-surgical-hair-replacement-for-men2.png",
      "https://www.radiancehairstudio.com/help-image-1.png"
    ],
    "additionalType": "https://www.radiancehairstudio.com/non-surgical-hair-replacement-for-men.php",
    "category": "https://www.radiancehairstudio.com/non-surgical-hair-replacement-for-men.php",
    "description": "Explore the best non surgical hair replacement for men in Delhi. Radiance Hair Studio offers natural-looking solutions to hair loss. Book your consultation now.",
    "disambiguatingDescription": "Non surgical hair replacement for men in Delhi",
    "serviceType": "Non-Surgical Hair Replacement for Men's"
  };   

  // const faqs = [
  //   {
  //     title: "Is the process of replacement going to damage my natural hair?",
  //     description:
  //       "The extensions are attached on your head with the help of glue, tapes or clips. In each case, the adhesives are applied on your scalp, which has no connection with your natural hair-roots. So, there can remain no chance of hair damage for you.",
  //   },
  //   {
  //     title: "Is the process of replacement pain giving?",
  //     description:
  //       "The process that we apply at Radiance is a pure Non-Surgical hair Replacement in Delhi. Hence, there is nothing to do with pain. The full process we follow is an externally applied one.        ",
  //   },
  //   {
  //     title: "What to do, if I want to change my hairstyle frequently?",
  //     description:
  //       "We at Radiance will give you lots of options. You can go for bonding, where you can change your styles just by coloring your hair on regular basis. If you choose a general extension, then also you can change your appearance quite frequently, but you will have to visit us at Radiance each time for that.",
  //   },
  //   {
  //     title: "After getting an extension, how can I maintain my natural hair?",
  //     description:
  //       "First of all your natural hair will be trimmed. Secondly, you can go for clip method of hair extensions in Delhi. There you can open and re-wear the cap any time. Finally, in all other types of extensions, Radiance provides you with natural human hair as an extension. So, you can apply shampoos, serums, and anything for maintenance.",
  //   },
  // ];

  const sectionS1 = [
    {
      title: "Hair Patch for Men",
      img: "/hair-patch-for-men.png",
    },
    {
      title: "Human Hair Extension",
      img: "/7.png",
    },
    {
      title: "Hair Weaving",
      img: "/8.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/9.png  ",
    }
  ];

  const sectionS2 = [
    {
      img: "/service-images/Non-Surgical-Hair-Replacement-for-Men's.jpg",
      content: (
        <div>
          <h2>Non-Surgical Hair Replacement for Men's</h2>
          <p>
          Fashion is the underlined statement in the city life today. Right now, it's imperative that you get in sync with it. . Then how can you go ahead with baldness?Even if you are not bald, you still need to establish your own personal style trademark. When you get hair extensions in Delhi, you can get whatever you need.
          </p>
          <h2>Give a new start with Radiance</h2>
          <p>
          At Radiance Hair Studio, we professionally take care of your hair and hairstyle while also making sure that your goal is fully achieved. We provide all kinds of non-surgical hair replacement in Delhi,and more importantly, you will be presented with a variety of choices, such as male wigs and <a href="/hair-extensions-for-men.php" style={{ color: "#373737"}}>extensions</a> in various hues and designs. So, get in touch with us now and display your chic style. 
          </p>
          <p>
          When you decide to have a hair extension, the main thing you need to consider is the procedure that will be used. Some questions sprang to mind, such as if the procedure will be painful and whether you will receive assistance. Go over the advantages and the solutions to some of the queries that are on your mind. That will assist you in choosing the new style in a more effective manner.

          </p>
          <p>
          Everything you need is taken care of at Radiance. You won't experience any stress because all of our hairstyle plans are applied externally. Additionally, our experts are always happy to help so that your style stays current with your friends and followers.
          </p>
        </div>
      ),
    },
  ];
  
  const sectionS3 = [
    {
      smallImg: "/non-surgical-hair-replacement-for-men1.png",
      largeImg: "/non-surgical-hair-replacement-for-men2.png",
      content: (
        <div>
          <h2>Advantages of Hair Replacement Systems</h2>
  
          <ul>
            <li>
            Looking to enhance your style but hesitant to experiment with your natural hair? Male wigs and hair extensions provide the perfect solution, allowing you to achieve a trendy look effortlessly. If you are dealing with baldness or partial hair loss, there’s no need to worry—hair wigs and extensions can instantly give you a fuller, more confident appearance.
            </li>
            <li>
            Since hair extensions are applied externally, they pose no risk of side effects. The adhesives and tapes used for attachment are of premium quality, ensuring a safe and comfortable experience. These high-standard materials are designed to be gentle on your scalp while providing a secure hold.
            </li>
            <li>
            Your natural hair remains completely unaffected, as the extensions are placed on the scalp without interfering with hair roots. This eliminates the risk of chemical damage, keeping your existing hair safe and healthy.
            </li>
            <li>
            Hair extensions are also easy to maintain. You can wash them with shampoos, apply conditioners, and even use serums without any concerns. Clip-on extensions offer added convenience, allowing you to remove and reapply them as needed. Whether for everyday use or special occasions, they provide a hassle-free way to enhance your look effortlessly.
            </li>
          </ul>
        </div>
      ),
    },
  ];
  
  const sectionS4 = [
    {
      title: "Questions often asked by our clients: FAQs",
      description:"",
      description2: "",
      faq: [
        {
          title: "Is the process of replacement going to damage my natural hair?",
          description:
            "The extensions are attached on your head with the help of glue, tapes or clips.The adhesives are placed to your scalp in each instance, which is unrelated to your natural hair roots. Therefore, you cannot continue to have any risk of hair damage. ",
        },
        {
          title: "Is the process of replacement pain giving?",
          description:
            "The process that we apply at Radiance is a pure Non-Surgical hair Replacement in Delhi. Hence, there is nothing to do with pain. The full process we follow is an externally applied one.",
        },
        {
          title: "What to do, if I want to change my hairstyle frequently?",
          description:
            "We at Radiance will give you lots of options. You can go for bonding, where you can change your styles just by coloring your hair on regular basis. If you choose a general extension, then also you can change your appearance quite frequently, but you will have to visit us at Radiance each time for that.",
        },
        {
          title: "After getting an extension, how can I maintain my natural hair?",
          description:
            "First of all your natural hair will be trimmed. Secondly, you can go for clip method of hair extensions in Delhi. There you can open and re-wear the cap any time. Finally, in all other types of extensions, Radiance provides you with natural human hair as an extension. So, you can apply shampoos, serums, and anything for maintenance.",
        }
      ],
    },
  ];
  

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <TitleBar2 title="Non-Surgical Hair Replacement for Men's" banner="/non-surgical-hair-replacement-for-men-banner.png"/>
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Non-Surgical Hair Replacement for Men's" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default NonSurgicalHairReplacementforMen;
